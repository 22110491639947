import { Locale } from '../constants/locale-constants';

/**
 * Returns the language 'code' for a given locale.
 * @example
 * locale: 'en-GB'
 * returns: 'en'
 * @param locale
 */
export function getLanguageCodeFromLocale(locale: Locale): string {
    return locale.replace('_', '-').substring(0, 2);
}

/**
 * Returns the country 'code' for a given locale.
 * @example
 * locale: 'en_GB'
 * returns: 'GB'
 * @param locale
 */
export function getCountryCodeFromLocale(locale: Locale): string {
    return locale.split('_')?.[1];
}

/**
 * Returns the locale from a language string.
 * @example
 * language: 'en' or 'en_GB
 * returns: 'en_GB'
 * @param language
 * @param defaultLocale
 */
export function getLocaleFromLanguage(language: string, defaultLocale: string = Locale.en_GB): Locale {
    if (!language) {
        return Locale[defaultLocale];
    }
    return language.length === 2 && Locale[`${language}_${language.toUpperCase()}`]
        ? Locale[`${language}_${language.toUpperCase()}`]
        : Locale[language.replace('-', '_')]
          ? Locale[language.replace('-', '_')]
          : Locale[Object.keys(Locale).find((locale) => locale.startsWith(language))]
            ? Locale[Object.keys(Locale).find((locale) => locale.startsWith(language))]
            : Locale[defaultLocale];
}

/**
 * Returns the locale as a string.
 * @example
 * locale: 'en_GB'
 * returns: 'en-EN'
 * @param locale
 */
export function getLocaleStringFromLocale(locale: Locale): string {
    return locale.replace('_', '-');
}

/**
 * Returns the name of the language for a given locale.
 * @example
 * locale: 'en-GB'
 * returns: 'British English'
 * @param localeToTranslate
 * @param locale
 */
export function getLanguageNameFromLocale(localeToTranslate: string, locale?: string): string | null {
    if (!(localeToTranslate?.length > 0)) {
        return null;
    }
    const currentLocale = locale || 'de';
    return new Intl.DisplayNames(currentLocale.replace('_', '-'), { type: 'language' }).of(
        localeToTranslate.replace('_', '-')
    );
}

/**
 * Returns the name of the location for a given locale.
 * @example
 * locale: 'en-GB'
 * returns: 'United Kingdom'
 * @param locale
 */
export function getLocationNameFromLocale(locale: Locale): string {
    return new Intl.DisplayNames(locale.replace('_', '-'), { type: 'region' }).of(
        locale.replace('_', '-').split('-')[1]
    );
}
