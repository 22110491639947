import { TFunction } from 'next-i18next';
import React, { Dispatch, SetStateAction } from 'react';

import LinkWithStyle from '@/components/link/LinkWithStyle';
import ListLoadingSpinner from '@cineamo/legacy-frontend-lib/src/components/ui/animation/ListLoadingSpinner';
import Calendar from '@cineamo/legacy-frontend-lib/src/components/ui/calendar/Calendar';
import ListPlaceholderSimple from '@cineamo/legacy-frontend-lib/src/components/ui/list/ListPlaceholderSimple';

import { EventRequestDto } from '@cineamo/legacy-frontend-lib/src/models/event-request/EventRequestDto.types';

import { formatDateTime } from '@cineamo/legacy-frontend-lib/src/helper/date-helper';

import { LocaleMiddlewareProps } from '@/src/middleware/localeMiddleware';

type CinemaDashboardScheduledEventsProps = LocaleMiddlewareProps & {
    selectedDate: string | Date;
    setSelectedDate: Dispatch<SetStateAction<string | Date>>;
    eventRequestsByDatePlanned: EventRequestDto[];
    isLoadingEventRequestsByDatePlanned: boolean;
    t: TFunction;
};
export function CinemaDashboardScheduledEvents(props: CinemaDashboardScheduledEventsProps) {
    const {
        selectedDate,
        setSelectedDate,
        eventRequestsByDatePlanned,
        isLoadingEventRequestsByDatePlanned,
        locale,
        t
    } = props;

    const selectedDateConverted = new Date(selectedDate);

    const selectedDateDay = new Date(selectedDateConverted).getDate();
    const todayMonth = new Intl.DateTimeFormat(locale, { month: 'long' }).format(selectedDateConverted);
    const todayYear = selectedDateConverted.getUTCFullYear();

    return (
        <div className="rounded-8 bg-darkBlue bg-opacity-20 space-y-28 lg:space-y-0 overflow-hidden text-ellipsis w-full flex flex-col lg:flex-row">
            <div className="w-full lg:1/2 border-b-1 lg:border-b-0 lg:border-r-1 border-white border-opacity-20 p-16">
                <div className="flex flex-row text-white opacity-50 items-center space-x-12">
                    <div className="text-body-56">{selectedDateDay}</div>
                    <div className="flex flex-col">
                        <div>{todayMonth}</div>
                        <div>{todayYear}</div>
                    </div>
                </div>
                <div className="space-y-16">
                    <h1 className="text-body-bold-16">{t('scheduled-events')}</h1>
                    <ul>
                        {!isLoadingEventRequestsByDatePlanned ? (
                            eventRequestsByDatePlanned.length > 0 ? (
                                eventRequestsByDatePlanned.map((event, index) => {
                                    return (
                                        <li key={index}>
                                            <LinkWithStyle
                                                href={
                                                    event._embedded?.cinema?.slug &&
                                                    `/${event._embedded?.cinema?.slug}/event-requests/${event.id}`
                                                }>
                                                <span>
                                                    {`${formatDateTime(event.finalDatetime, locale, {
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })} - ${event.name}`}
                                                </span>
                                            </LinkWithStyle>
                                        </li>
                                    );
                                })
                            ) : (
                                <div className="flex">
                                    <ListPlaceholderSimple text={t('no-events-scheduled')} />
                                </div>
                            )
                        ) : (
                            <ListLoadingSpinner />
                        )}
                    </ul>
                </div>
            </div>
            <div className="w-full lg:1/2 p-16">
                <Calendar
                    className="bg-transparent w-full lg:w-300 mx-auto"
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                />
            </div>
        </div>
    );
}
