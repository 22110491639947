import classnames from 'classnames';
import Image from 'next/future/image';
import React from 'react';

import { loadAsset } from '@cineamo/legacy-frontend-lib/src/helper/asset-helper';

import useLastSelectedCinema from '@/src/hooks/useLastSelectedCinema';

import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

const ImageCinemaPlaceholder = loadAsset('/images/placeholders/im-defaultCinemaImage.png');
const ImageLogoPlaceholder = loadAsset('/images/placeholders/im-defaultCinemaChainImage.png');

function SideNavigationHeader({ isSideBarCollapsed }: { isSideBarCollapsed: boolean }) {
    const { user } = useUserStore();
    const { lastSelectedCinema: cinema } = useLastSelectedCinema(user);

    return (
        <div className="relative flex flex-col w-full h-auto">
            <div className="relative w-full h-[100px] flex flex-col items-center mb-[16px]">
                <Image
                    className="w-full h-full object-cover gradient-mask-b-70 overflow-clip"
                    src={cinema?.profileImageUrl || ImageCinemaPlaceholder}
                    width={300}
                    height={100}
                    alt="Cinema Profile Image"
                />
                <div
                    className={classnames(
                        'absolute -bottom-[16px] rounded-full overflow-clip shadow-[0_4px_12px_rgba(0,0,35,0.1)]',
                        'transition-all duration-300',
                        isSideBarCollapsed ? 'w-[32px] h-[32px]' : 'w-[64px] h-[64px]'
                    )}>
                    <Image
                        className="w-full h-full object-cover"
                        src={cinema?.logoImageUrl || ImageLogoPlaceholder}
                        width={100}
                        height={100}
                        alt="Cinema Logo"
                    />
                </div>
            </div>
            <div
                className={classnames(
                    'w-[300px] flex flex-col self-center items-center px-18 overflow-clip',
                    'transition-all duration-200',
                    isSideBarCollapsed ? 'max-h-0 opacity-0' : 'max-h-screen opacity-100 pt-[8px]'
                )}>
                {cinema?.name && (
                    <span className="max-w-full text-body-14 font-montserrat-semibold leading-[24px] tracking-[5%] text-center line-clamp-2">
                        {cinema.name}
                    </span>
                )}
                {cinema?.city && (
                    <span className="max-w-full text-body-12 text-center line-clamp-2">{cinema.city}</span>
                )}
            </div>
        </div>
    );
}

export default SideNavigationHeader;
