import classnames from 'classnames';
import { ReactNode } from 'react';

import NavigationBar from '@/components/navigation/NavigationBar';

type SidebarLayoutProps = {
    className?: string;
    nav?: JSX.Element;
    content: ReactNode | JSX.Element;
    pageProps: Record<string, unknown>;
    isSidebarCollapsed?: boolean;
    setIsSidebarCollapsed?: (isCollapsed: boolean) => void;
};

function SidebarNavigationLayout(props: SidebarLayoutProps): JSX.Element {
    const { className, nav, content } = props;

    return (
        <div className={classnames('relative w-full h-full overflow-hidden flex flex-row', className)}>
            {nav && (
                <div
                    className={classnames(
                        'z-menu-button absolute inset-y-0 left-0 h-full transform flex-none duration-150',
                        'md:relative translate-x-0 ease-out',
                        'transition-width ',
                        { '-translate-x-full': props.isSidebarCollapsed }
                    )}>
                    {nav}
                </div>
            )}

            <div className="relative w-full md:w-0 flex flex-col flex-auto pl-[81px] md:pl-0">
                <NavigationBar pageProps={props.pageProps} />
                <div className="flex flex-col h-0 flex-auto font-montserrat-regular text-white overflow-auto">
                    {content}
                </div>
            </div>
        </div>
    );
}

export default SidebarNavigationLayout;
