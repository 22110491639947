import classnames from 'classnames';
import Link from 'next/link';
import { CSSProperties } from 'react';

import { IconClose16 } from '../../../icons/menu/ic-close';

export type BadgeProps = {
    className?: string;
    id?: string;
    leadingIcon?: string | JSX.Element;
    name: string | JSX.Element;
    textColor?: string;
    bgColor?: string;
    badgeStyle?: BadgeStyle;
    hoverable?: boolean;
    href?: string;
    onBadgeClick?: (badgeId: string, badgeTitle: string) => void;
    wrap?: string;
    style?: CSSProperties;
    isSelected?: boolean;
};

export enum BadgeStyle {
    default = 'text-body-10 rounded-3 uppercase px-6 py-2 bg-opacity-20',
    large = 'text-body-bold-12 rounded-3 lowercase px-6 py-1 bg-opacity-20',
    large_xl = 'text-body-12 uppercase rounded-3 px-12 py-8',
    large_xxl = 'text-overline-16 uppercase rounded-3 px-16 py-1',
    large_default_case = 'text-body-bold-14 rounded-3 px-6 py-1 bg-opacity-20',
    frameless = 'text-body-bold-12 text-gray-light text-opacity-50 rounded-3 lowercase px-6 py-1 bg-opacity-0',
    filter = 'text-body-bold-14 rounded-4 px-8 py-4',
    filter12 = 'text-body-bold-12 rounded-4 px-12 py-4'
}

function Badge(props: BadgeProps): JSX.Element {
    let hoverableStyle = '';
    if (props.hoverable) {
        hoverableStyle = `hover:${
            props.bgColor || 'bg-white'
        } transition-all duration-150 hover:text-darkBlue active:opacity-50 cursor-pointer`;
    }

    function onClickInternal(event, badgeId, badgeTitle) {
        if (props.onBadgeClick) {
            props.onBadgeClick(badgeId, badgeTitle);
        }
    }

    const badge = (
        <div
            className={classnames('flex items-center max-w-full')}
            title={typeof props.name === 'string' ? props.name : undefined}
            style={props.style}>
            <div
                className={classnames(
                    props.className,
                    props.badgeStyle || BadgeStyle.default,
                    props.bgColor || 'bg-white',
                    props.textColor || 'text-gray-light',
                    hoverableStyle,
                    props.wrap || 'whitespace-nowrap',
                    'font-montserrat-semibold',
                    'select-none',
                    { 'space-x-8 flex flex-row items-center': props.isSelected }
                )}
                onClick={(e) => {
                    onClickInternal(e, props.id, props.name);
                }}>
                {props.leadingIcon}
                {props.name && <span>{props.name}</span>}
                {props.isSelected && <IconClose16 className="opacity-20" />}
            </div>
        </div>
    );

    return props.href && props.href.startsWith('/') ? (
        <Link href={props.href}>{badge}</Link>
    ) : props.href ? (
        <a href={props.href}>{badge}</a>
    ) : (
        badge
    );
}

export default Badge;
