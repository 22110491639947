import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconWarningSolid48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                  fill={props.fill || 'currentColor'}/>
            <path d="M11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17Z"
                  fill={props.foregroundcolor || 'white'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13 6L13 14L11 14L11 6L13 6Z"
                  fill={props.foregroundcolor || 'white'}/>
        </svg>
    );
}

export function IconWarningSolid24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                  fill={props.fill || 'currentColor'}/>
            <path d="M11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17Z"
                  fill={props.foregroundcolor || 'white'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13 6L13 14L11 14L11 6L13 6Z"
                  fill={props.foregroundcolor || 'white'}/>
        </svg>
    );
}

export function IconWarningSolid16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16"
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                  fill={props.fill || 'currentColor'}/>
            <path d="M7 13C7 13.5523 7.44772 14 8 14C8.55228 14 9 13.5523 9 13C9 12.4477 8.55228 12 8 12C7.44772 12 7 12.4477 7 13Z"
                  fill={props.foregroundcolor || 'white'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 2L9 10L7 10L7 2L9 2Z"
                  fill={props.foregroundcolor || 'white'}/>
        </svg>
    );
}