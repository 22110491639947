import classnames from 'classnames';
import React from 'react';

import { IconWarningTriangle16 } from '../../../icons/ic-warning-triangle';

type InputCountLabelProps = {
    className?: string;
    currentCount: number;
    recommendedCount?: number;
    maxCount?: number;
    recommendedMessage?: string;
    overLengthMessage?: string;
};

export default function InputCountLabel({
    className,
    currentCount,
    recommendedCount,
    maxCount,
    recommendedMessage,
    overLengthMessage
}: InputCountLabelProps) {
    const hasReachedMaxLength = maxCount && currentCount >= maxCount;
    const hasReachedRecommendedLength = recommendedCount >= 0 && currentCount > recommendedCount;
    const message = hasReachedMaxLength
        ? overLengthMessage
        : hasReachedRecommendedLength
          ? recommendedMessage
          : undefined;
    return (
        <div
            className={classnames(className, 'flex space-x-[2px] text-body-12 items-center justify-between', {
                'text-[#FE7600]': hasReachedRecommendedLength,
                'text-red': hasReachedMaxLength,
                'text-darkBlue/50': !hasReachedMaxLength && !hasReachedRecommendedLength
            })}>
            {message && <span>{message}</span>}
            <span className="duration-150 ml-auto">
                {[
                    currentCount,
                    currentCount < maxCount && recommendedCount >= 0 ? recommendedCount : (maxCount ?? recommendedCount)
                ]
                    .filter((it) => it >= 0)
                    .join('/')}
            </span>
            {hasReachedMaxLength && (
                <IconWarningTriangle16
                    className={classnames(
                        'flex h-[12px] duration-150 overflow-clip origin-center',
                        hasReachedMaxLength ? 'max-w-auto opacity-100' : 'w-0 opacity-0'
                    )}
                />
            )}
        </div>
    );
}
