import ImMarketingAssistant from '@cineamo/legacy-frontend-lib/src/illustrations/im-marketingAssistant.svg';
import { TFunction } from 'next-i18next';
import Image from 'next/future/image';
import React from 'react';

import LinkWithStyle from '@/components/link/LinkWithStyle';
import { MarketingAssistantActiveLabel } from '@/components/pages/marketing-assistant/MarketingAssistantActiveLabel';
import Button from '@cineamo/legacy-frontend-lib/src/components/ui/buttons/Button';
import AdminCard from '@cineamo/legacy-frontend-lib/src/components/ui/cards/AdminCard';

import { DOMAIN_URL } from '@cineamo/legacy-frontend-lib/src/constants/http-constants';

type MarketingAssistantInactiveCardProps = {
    locale: string;
    t: TFunction;
};
export function MarketingAssistantInactiveCard(props: MarketingAssistantInactiveCardProps): JSX.Element {
    const { locale, t } = props;

    const marketingAssistantCalculator = `${DOMAIN_URL}${
        locale ? `/${locale}` : ''
    }/cinema-operator/marketing-assistant`;

    return (
        <AdminCard
            className="relative w-full"
            leadingComponent={<MarketingAssistantActiveLabel />}
            trailingComponentClassName="overflow-visible"
            trailingComponent={
                <div className="flex flex-row space-x-24 overflow-visible items-center">
                    <div className="relative w-[32rem] h-[15.2rem] hidden xl:flex">
                        <div className="absolute top-0 left-0 flex w-[32rem] h-[32rem] overflow-visible">
                            <Image
                                className="absolute flex-none w-full h-full object-contain -mt-16"
                                src={ImMarketingAssistant.src}
                                width={1000}
                                height={1000}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="flex flex-col items-center text-center flex-auto px-24 space-y-8">
                        <span className="text-body-bold-14">{t('dashboard:no-time-for-marketing')}</span>
                        <span className="text-body-14">{t('dashboard:marketing-assistant-automated-promotion')}</span>
                        <LinkWithStyle
                            href={marketingAssistantCalculator}
                            target="_blank">
                            <Button>{t('dashboard:more-information')}</Button>
                        </LinkWithStyle>
                    </div>
                </div>
            }
        />
    );
}
