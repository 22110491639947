import classnames from 'classnames';
import Image from 'next/image';
import React, { SyntheticEvent } from 'react';

import Tooltip, { TooltipDirection } from '../tooltip/Tooltip';

export type ActionIconProps = {
    className?: string;
    icon: JSX.Element | string;
    tooltipText?: string;
    tooltipDirection?: TooltipDirection;
    action: (event: SyntheticEvent) => void;
    disabled?: boolean;
};

export function ActionIcon(props: ActionIconProps): JSX.Element {
    const { icon, action, disabled } = props;
    const iconWithAction = (
        <div
            className={classnames('relative flex w-24 h-24 items-center clickable-element', {
                'opacity-50': disabled
            })}
            onClick={disabled ? undefined : action}>
            {typeof icon === 'string' ? (
                <Image
                    layout="fill"
                    objectFit="cover"
                    src={icon}
                    alt="Action Icon"
                />
            ) : React.isValidElement(icon) ? (
                icon
            ) : null}
        </div>
    );
    return (
        <div className={classnames(props.className, 'flex items-center')}>
            {props.tooltipText ? (
                <Tooltip
                    tooltipText={props.tooltipText || ''}
                    direction={props.tooltipDirection || TooltipDirection.Top}>
                    {iconWithAction}
                </Tooltip>
            ) : (
                iconWithAction
            )}
        </div>
    );
}
