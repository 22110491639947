import classnames from 'classnames';
import { useEffect, useState } from 'react';

export type ListLoadingSpinnerProps = {
    className?: string;
    dotClass?: string;
};

export default function ListLoadingSpinner(props: ListLoadingSpinnerProps): JSX.Element {
    const { className, dotClass } = props;

    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    if (!mounted) {
        return null;
    }

    return (
        <div className={classnames(className || 'py-38', 'w-full justify-center flex')}>
            <div className="flex space-x-8 items-center">
                <div className="flex h-28 items-center animate-bounce">
                    <div className={classnames(dotClass || 'bg-cyan', 'h-6 w-6 rounded-full')} />
                </div>
                <div className="flex h-28 items-center animate-bounce200">
                    <div className={classnames(dotClass || 'bg-cyan', 'h-6 w-6 rounded-full')} />
                </div>
                <div className="flex h-28 items-center animate-bounce400">
                    <div className={classnames(dotClass || 'bg-cyan', 'h-6 w-6 rounded-full')} />
                </div>
            </div>
        </div>
    );
}
