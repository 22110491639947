import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconWarningTriangle48(props: CustomSVGProps): JSX.Element {
    return (
        <svg
            {...props}
            width="48" height="48" viewBox="0 0 48 48" fill={props.fill || 'currentColor'}
            xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24 4C25.4291 4 26.7496 4.7624 27.4641 6L44.7846 36C45.4992 37.2376 45.4992 38.7624 44.7846 40C44.0701 41.2376 42.7496 42 41.3205 42H6.6795C5.25044 42 3.92993 41.2376 3.2154 40C2.50087 38.7624 2.50087 37.2376 3.2154 36L20.5359 6C21.2504 4.7624 22.571 4 24 4ZM24 8L6.6795 38H41.3205L24 8Z"
                  fill={props.fill || 'currentColor'}/>
            <path
                d="M22.4409 19.4153C22.8745 19.8051 23.3943 20 24 20C24.6058 20 25.1224 19.8051 25.5496 19.4153C25.9832 19.0193 26.2 18.546 26.2 17.9954C26.2 17.4509 25.9832 16.9838 25.5496 16.594C25.1224 16.198 24.6058 16 24 16C23.3943 16 22.8745 16.198 22.4409 16.594C22.0137 16.9838 21.8 17.4509 21.8 17.9954C21.8 18.546 22.0137 19.0193 22.4409 19.4153Z"
                fill={props.fill || 'currentColor'}/>
            <rect x="22" y="22" width="4" height="14" fill={props.fill || 'currentColor'}/>
        </svg>


    );
}

export function IconWarningTriangle24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 1C12.7145 1 13.3748 1.3812 13.7321 2L22.3923 17C22.7496 17.6188 22.7496 18.3812 22.3923 19C22.035 19.6188 21.3748 20 20.6603 20H3.33975C2.62522 20 1.96497 19.6188 1.6077 19C1.25044 18.3812 1.25044 17.6188 1.6077 17L10.268 2C10.6252 1.3812 11.2855 1 12 1ZM12 3L3.33975 18H20.6603L12 3Z"
                  fill={props.fill || 'currentColor'}/>
            <path
                d="M11.2204 8.70766C11.4372 8.90255 11.6971 9 12 9C12.3029 9 12.5612 8.90255 12.7748 8.70766C12.9916 8.50967 13.1 8.27301 13.1 7.99768C13.1 7.72544 12.9916 7.49188 12.7748 7.29698C12.5612 7.09899 12.3029 7 12 7C11.6971 7 11.4372 7.09899 11.2204 7.29698C11.0068 7.49188 10.9 7.72544 10.9 7.99768C10.9 8.27301 11.0068 8.50967 11.2204 8.70766Z"
                fill={props.fill || 'currentColor'}/>
            <rect x="11" y="10" width="2" height="7" fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconWarningTriangle16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="18" height="16" viewBox="0 0 18 16" fill={props.fill || 'currentColor'}
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.01705 11.1989V6.83523H9.40625V11.1989H8.01705ZM8.71307 6.3267C8.51799 6.3267 8.35038 6.26231 8.21023 6.13352C8.07008 6.00284 8 5.84564 8 5.66193C8 5.48011 8.07008 5.32481 8.21023 5.19602C8.35038 5.06534 8.51799 5 8.71307 5C8.91004 5 9.07765 5.06534 9.21591 5.19602C9.35606 5.32481 9.42614 5.48011 9.42614 5.66193C9.42614 5.84564 9.35606 6.00284 9.21591 6.13352C9.07765 6.26231 8.91004 6.3267 8.71307 6.3267Z"
                fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.00001 0C9.69125 0 10.3335 0.356938 10.6985 0.94396L16.7607 10.694C17.1441 11.3107 17.1631 12.0868 16.8103 12.7216C16.4576 13.3563 15.7884 13.75 15.0622 13.75H2.93784C2.21162 13.75 1.54247 13.3563 1.18968 12.7216C0.836896 12.0868 0.855916 11.3107 1.23937 10.694L7.30155 0.94396C7.66654 0.356938 8.30878 0 9.00001 0ZM9.00001 2L2.93784 11.75H15.0622L9.00001 2Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}
