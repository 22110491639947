import { NextRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocalStorage } from 'react-use';

import { CinemaDto } from '@cineamo/legacy-frontend-lib/src/models/cinema/CinemaDto.types';
import { UserMeDto } from '@cineamo/legacy-frontend-lib/src/models/user/UserMeDto.types';

import { getCinemaById } from '@cineamo/legacy-frontend-lib/src/api/cinema/cinema-api';

import { CINEMA_ID_PLACEHOLDER, cinemaIdOrSlug } from '@/src/helper/cinema-helper';
import { formatDate_yyyyMMddTHHmmssZ } from '@cineamo/legacy-frontend-lib/src/helper/date-helper';

import useRouterQueryKeyValue from '@cineamo/legacy-frontend-lib/src/hooks/useRouterQueryKeyValue';

import { useCinemaStore } from '@cineamo/legacy-frontend-lib/src/store/cinema/cinemaStore';

type LocalStorageLastSelectedCinema = {
    lastUpdated: string;
    user: number;
    cinema: CinemaDto;
};

type LastSelectedCinemaReturn = {
    lastSelectedCinema?: CinemaDto;
    setLastSelectedCinema?: Dispatch<SetStateAction<CinemaDto>>;
    clearLastSelectedCinema?: () => void;
};

function useLastSelectedCinema(user?: UserMeDto, router?: NextRouter): LastSelectedCinemaReturn {
    const [lastSelectedCinemas, setLastSelectedCinemas, clearLastSelectedCinemas] =
        useLocalStorage<LocalStorageLastSelectedCinema[]>('last-selected-cinemas');
    // TODO: Check if we need the cinemaStore here!
    const { cinema, setCinema, clearCinema } = useCinemaStore();
    const { queryValue: cinemaIdFromQuery } = useRouterQueryKeyValue('cinemaId');

    useEffect(() => {
        const lastSelectedCinemaForUser: LocalStorageLastSelectedCinema =
            user && lastSelectedCinemas?.find((lastSelectedCinema) => lastSelectedCinema.user === user.id);
        if (
            lastSelectedCinemaForUser &&
            new Date().getTime() - new Date(lastSelectedCinemaForUser?.lastUpdated).getTime() > 1000 * 60 * 60 * 24
        ) {
            const allLastSelectedCinemasWithoutUserCinema: LocalStorageLastSelectedCinema[] =
                lastSelectedCinemas?.filter((lastSelectedCinema) => lastSelectedCinema.user !== user.id);
            setLastSelectedCinemas(allLastSelectedCinemasWithoutUserCinema);
            clearCinema();
        } else if (
            lastSelectedCinemaForUser &&
            new Date().getTime() - new Date(lastSelectedCinemaForUser?.lastUpdated).getTime() <= 1000 * 60 * 60 * 24
        ) {
            getCinemaById(lastSelectedCinemaForUser.cinema.id).then((cinema) => user && cinema && updateCinema(cinema));
        } else if (
            lastSelectedCinemaForUser?.cinema !== cinema &&
            cinemaIdFromQuery &&
            cinemaIdFromQuery !== CINEMA_ID_PLACEHOLDER
        ) {
            getCinemaById(cinemaIdFromQuery).then((cinema) => user && cinema && updateCinema(cinema));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: We may need some timing function, which updates the 'lastUpdated' timestamp
    // useInterval(() => updateLocalStorageLastSelectedCinemaLastUpdated(), 10000);
    //
    // function updateLocalStorageLastSelectedCinemaLastUpdated() {
    //     if (lastSelectedCinemas) {
    //         updateCinema(lastSelectedCinemas.cinema);
    //     }
    // }

    function updateCinema(cinema?: CinemaDto) {
        const existingLastSelectedCinemaIndex = lastSelectedCinemas?.findIndex(
            (lastSelectedCinema) => lastSelectedCinema.user === user.id
        );
        const lastSelectedCinemasCurrent: LocalStorageLastSelectedCinema[] = lastSelectedCinemas || [];
        if (existingLastSelectedCinemaIndex >= 0) {
            if (cinema) {
                lastSelectedCinemasCurrent[existingLastSelectedCinemaIndex].lastUpdated = formatDate_yyyyMMddTHHmmssZ(
                    new Date()
                );
                lastSelectedCinemasCurrent[existingLastSelectedCinemaIndex].cinema = cinema;
            } else {
                lastSelectedCinemasCurrent.splice(existingLastSelectedCinemaIndex, 1);
            }
        } else if (cinema) {
            lastSelectedCinemasCurrent.push({
                lastUpdated: formatDate_yyyyMMddTHHmmssZ(new Date()),
                user: user.id,
                cinema: cinema
            });
        }

        updateCinemaInUrl(router, cinema);

        setLastSelectedCinemas(lastSelectedCinemasCurrent);
        if (cinema) {
            setCinema(cinema);
        } else {
            clearCinema();
        }
    }

    return {
        lastSelectedCinema: cinema,
        setLastSelectedCinema: updateCinema,
        clearLastSelectedCinema: clearLastSelectedCinemas
    };
}

export function updateCinemaInUrl(router: NextRouter, cinema: CinemaDto) {
    router
        ?.replace(
            {
                pathname: router.pathname,
                query: {
                    ...router.query,
                    cinemaId: cinemaIdOrSlug(cinema)
                }
            },
            undefined,
            {
                shallow: true
            }
        )
        ?.then();
}

export default useLastSelectedCinema;
