import classnames from 'classnames';
import React, { CSSProperties } from 'react';

export type Skeletonable = {
    isLoading?: boolean;
};

export enum SkeletonViewStyle {
    default,
    default_on_white_bg
}

type SkeletonViewProps = {
    className?: string;
    lines?: number;
    style?: SkeletonViewStyle | CSSProperties;
    customWidth?: 'w-120' | 'w-250' | 'w-full';
    customHeight?: 'h-18' | 'h-250' | 'h-auto' | 'h-full';
};

function SkeletonView(props: SkeletonViewProps): JSX.Element {
    const { className, style, lines } = props;

    let bgColor = 'bg-white';
    switch (style) {
        case SkeletonViewStyle.default_on_white_bg:
            bgColor = 'bg-darkBlue';
            break;
    }

    function width() {
        switch (props.customWidth) {
            case 'w-full':
                return 'w-full';
            case 'w-250':
                return 'w-250';
            case 'w-120':
                return 'w-120';
            default:
                return 'w-48';
        }
    }

    function height() {
        switch (props.customHeight) {
            case 'h-full':
                return 'h-full';
            case 'h-18':
                return 'h-18';
            case 'h-250':
                return 'h-250';
            default:
                return 'h-auto';
        }
    }

    return (
        <div className={classnames('space-y-4', className)}>
            {[...Array(lines || 1)].map((it, index) => (
                <div
                    key={`skeletonLine#${index}`}
                    className={classnames(
                        bgColor,
                        width(),
                        height(),
                        'bg-opacity-20 animate-pulse rounded-3 whitespace-pre-wrap'
                    )}
                    style={{
                        backgroundColor:
                            typeof style === 'object' && 'backgroundColor' in style ? style.backgroundColor : undefined
                    }}>
                    &nbsp;
                </div>
            ))}
        </div>
    );
}

export default SkeletonView;
