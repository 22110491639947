export const cookieStorage = {
    getItem: (key: string): boolean | undefined => {
        if (typeof document === 'undefined') {
            return undefined;
        }
        const cookies = document.cookie
            .split(';')
            .map((cookie) => cookie.split('='))
            .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
        switch (cookies[key]) {
            case 'true':
                return true;
            case 'false':
                return false;
            default:
                return undefined;
        }
    },
    setItem: (
        key: string,
        value: boolean,
        options?: {
            expires?: Date;
        }
    ) => {
        const cookie = [`${key}=${value}`];
        if (options?.expires) {
            cookie.push(`expires=${options.expires.toUTCString()}`);
        }
        document.cookie = cookie.join('; ');
    }
};
