import classnames from 'classnames';
import { CSSProperties } from 'react';

export type DotSeparatedLabelProps = {
    className?: string;
    classNameLabel?: string;
    classNamePrimaryLabel?: string;
    classNameDot?: string;
    multiline?: boolean;
    labels: (string | JSX.Element)[];
    style?: CSSProperties;
};

function DotSeparatedLabel(props: DotSeparatedLabelProps): JSX.Element {
    const {
        className,
        style,
        classNameLabel,
        classNamePrimaryLabel,
        classNameDot,
        labels: labelProps,
        multiline
    } = props;

    const filteredLabels = labelProps.filter((label) => label !== undefined && label !== '' && label !== null);

    return (
        <div
            className={classnames(className, 'items-center', multiline !== true ? '' : 'flex flex-wrap')}
            style={style}>
            {filteredLabels.map((label, index) => (
                <span
                    key={`label#${index}`}
                    className={classnames(
                        multiline !== true ? '' : 'inline flex',
                        index === 0 && classNamePrimaryLabel ? classNamePrimaryLabel : classNameLabel
                    )}>
                    {label}
                    {index < filteredLabels.length - 1 && (
                        <span className={classnames(classNameDot, 'px-4', multiline !== true ? '' : 'inline-block')}>
                            ·
                        </span>
                    )}
                </span>
            ))}
        </div>
    );
}

export default DotSeparatedLabel;
